<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Plan {{ plan.id }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'plan-list'}">Plans</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ plan.planNo }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs class="tab-solid tab-solid-primary">
            <b-tab active>
              <template slot="title">
                <i class='mdi mdi-buffer'></i> Summary
              </template>
              <form class="forms-sample">
                <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <b-form-group label="Plan No"  label-for="planNo">
                          <b-form-input type="text" disabled id="planNo" v-model="plan.planNo" placeholder="Plan No"></b-form-input>
                        </b-form-group>
                        <b-form-group label="District"  label-for="district">
                          <b-form-input type="text" disabled id="district" v-model="plan.district" placeholder="District"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="city" horizontal label="City">
                          <b-form-input type="text" v-if="plan.city" v-model="plan.city.name" disabled id="city" placeholder="City"></b-form-input>
                        </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                    <h4 class="card-title">Points</h4>
                      <p v-if="noPoint">No Point is attached.</p>
                      <b-table striped hover :items="plan.points">
                      </b-table>
                    </div>
                  </div>
                </div>
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <b-form-group class="float-right">
                        <b-button v-if="plan.id" variant="success" :to="{name: 'plan-edit', params: {id: plan.id}}" class="mr-2">Edit</b-button>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              </form>
            </b-tab>
            <b-tab :disabled="this.plan.geo_json === null">
              <template slot="title">
                <i class=" mdi mdi-navigation"></i>  Geo JSON
              </template>
              <b-container>
                <b-row class="mb-2">
                  <b-col>Status</b-col>
                  <b-col><strong>{{ this.plan.process_readable}}</strong></b-col>
                </b-row>
                <b-row>
                  <b-col>Download</b-col>
                  <b-col><a :href="url" target="_blank">{{ this.plan.geo_json.file_name }}</a></b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import helper from '../../util/helper.js'

export default {
  name: 'plan-view',
  data() {
    return {
      plan: {},
      geo: []
    }
  },
  computed: {
    noPoint() {
      if(this.plan.points) {
        return !this.plan.points.length
      }
      return true
    },
    url() {
      if (this.plan.geo_json != null) return "http://test-api.mokhatat.com/storage/"+this.plan.geo_json.relative_url
      return ""
    }
  },
  components: {
    //StarRating
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    }
  },
  created() {
    this.$store.dispatch("plan/fetchPlan", this.$route.params.id)
    .then(res => {
       this.plan = res.data.data
    })
  }
}
</script>
